import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  text-align: left;
  align-items: left;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 1200px) {
    padding: 20px 25px;
  }
  @media screen and (max-width: 991px) {
    padding: 20px;
  }
  @media screen and (max-width: 768px) {
    flex-direction: row;
    ${(props) => props.noShadow && "flex-direction: column;"}
    text-align: left;
  }
  @media screen and (max-width: 576px) {
    flex-wrap: wrap;
  }
`;

const StyledImage = styled.img`
  ${(props) =>
    props.fixedHeight ? `height: ${props.fixedHeight}px;` : " height: 160px;"}
  ${(props) =>
    props.fixedHeight
      ? "max-width: auto;"
      : "max-width: 140px;"}

  margin-bottom: 20px;
  @media screen and (max-width: 991px) {
    max-width: 70%;
    height: 120px;
  }
  @media screen and (max-width: 768px) {
    ${(props) =>
      props.fixedHeight ? `height: ${props.fixedHeight}px;` : " height: auto;"}
    ${(props) =>
      props.fixedHeight
        ? `width: auto;`
        : " width: 160px;"}

    margin-right: 30px;
    margin-bottom: 0;
  }
  @media screen and (max-width: 576px) {
    width: 90px;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 15px;
  }
`;

const Title = styled.h4`
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.02px;
  color: #1e5bb5;
  margin-bottom: 16px;
  width: 100%;
  @media screen and (max-width: 991px) {
    font-size: 16px;
  }
  @media screen and (max-width: 576px) {
    text-align: center;
  }
`;

const Text = styled.span`
  font-size: 16px;
  color: rgba(36, 45, 65, 0.7);
  letter-spacing: 0.02px;
  font-weight: 300;
  @media screen and (max-width: 991px) {
    font-size: 14px;
  }
  @media screen and (max-width: 576px) {
    text-align: center;
    width: 100%;
    display: block;
  }
`;

const TechCard = (props) => {
  return (
    <Wrapper noShadow={props.noShadow}>
      <StyledImage
        fixedHeight={props.fixedHeight}
        src={props.img}
        alt={props.title}
      />
      <div>
        <Title>{props.title}</Title>
        <Text>{props.text}</Text>
      </div>
    </Wrapper>
  );
};

export default TechCard;