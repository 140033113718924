// extracted by mini-css-extract-plugin
export var active = "taskcards-module--active--62ec5";
export var aircraftTypeLink = "taskcards-module--aircraftTypeLink--dceed";
export var aircraftTypeTitle = "taskcards-module--aircraftTypeTitle--99837";
export var benefitsRow = "taskcards-module--benefitsRow--072ee";
export var benefitsText = "taskcards-module--benefitsText--343da";
export var cardsHolder = "taskcards-module--cardsHolder--d04e8";
export var centerText = "taskcards-module--centerText--0ac9d";
export var colAircraftTypes = "taskcards-module--colAircraftTypes--52b6e";
export var colFullImg = "taskcards-module--colFullImg--327f0";
export var colImageParent = "taskcards-module--colImageParent--072ce";
export var description = "taskcards-module--description--34548";
export var flexDescription = "taskcards-module--flexDescription--10b7a";
export var margin50 = "taskcards-module--margin50--fdb23";
export var mobileImage = "taskcards-module--mobileImage--c6ca2";
export var name = "taskcards-module--name--1dd3a";
export var nonMobileImage = "taskcards-module--nonMobileImage--501a4";
export var quote = "taskcards-module--quote--867d4";
export var quoteNoMargin = "taskcards-module--quoteNoMargin--4ab7b";
export var readyRow = "taskcards-module--readyRow--cd11b";
export var screenImg = "taskcards-module--screenImg--d10a7";
export var screenImgTC = "taskcards-module--screenImgTC--e936e";
export var screenText = "taskcards-module--screenText--908d1";
export var sectionTitle = "taskcards-module--section-title--e41cd";
export var subTitle = "taskcards-module--subTitle--a0981";
export var subTitleExperience = "taskcards-module--subTitleExperience--1f8b9";
export var subTitleLeft = "taskcards-module--subTitleLeft--57302";
export var subTitleRight = "taskcards-module--subTitleRight--fc1a2";
export var subTitleSmall = "taskcards-module--subTitleSmall--30b0d";
export var techCardCol = "taskcards-module--techCardCol--20194";
export var tick = "taskcards-module--tick--efbf3";
export var tickParent = "taskcards-module--tickParent--ca624";
export var tickWrapper = "taskcards-module--tickWrapper--c5d2f";
export var tickWrapperAC = "taskcards-module--tickWrapperAC--0a302";
export var title = "taskcards-module--title--31cb4";
export var titleLeft = "taskcards-module--titleLeft--7ff1f";
export var titleRow = "taskcards-module--titleRow--13d5c";
export var topCardWrapper = "taskcards-module--topCardWrapper--0b1ef";
export var topCardsParent = "taskcards-module--topCardsParent--0eceb";
export var typesImage = "taskcards-module--typesImage--50f62";
export var typesImageBusiness = "taskcards-module--typesImageBusiness--c1267";
export var typesImageCommercial = "taskcards-module--typesImageCommercial--b9e06";