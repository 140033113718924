// extracted by mini-css-extract-plugin
export var jarStorybook = "registry-module--jarStorybook--6d65b";
export var pageRowSpaced = "registry-module--pageRowSpaced--93c0a";
export var parentRowSpaced = "registry-module--parentRowSpaced--ae437";
export var quote = "registry-module--quote--7ad43";
export var quoteName = "registry-module--quoteName--4a1a2";
export var quoteNameCompany = "registry-module--quoteNameCompany--4a82f";
export var screenshot = "registry-module--screenshot--df68b";
export var screenshotCopy = "registry-module--screenshotCopy--ef6df";
export var title = "registry-module--title--3c696";
export var titleLeft = "registry-module--titleLeft--5c6e7";
export var titleRowSpaced = "registry-module--titleRowSpaced--bb44f";